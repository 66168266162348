import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner as StaticBanner } from "../../components/Banners/GenderAction"

import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { Partners } from "../../components/PartnersNew"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"
import { TwoChoice } from "../../components/twoChoice"
import { Questions } from "../../components/Questions"
import { QUESTIONS_FOR_HALVA_PKW } from "../../components/Questions/helpers"

const bannerTitle = `Розыгрыш до 100% <br/> кэшбэка на подарки`
const bannerDescription = `
<ul>
  <li>— до 36 месяцев рассрочки</li>
  <li>— до 5000 ₽ кэшбэка  </li>
</ul>
`

const formTitle = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const subTitlePartners = (
  <>
    Но это ещё не все подарки. Совершайте покупки от 3000 рублей по&nbsp;Халве, регистрируйте чеки
    от них на&nbsp;специальной странице и крутите Колесо фортуны. Вы можете получить промокоды на
    рассрочку до&nbsp;36 месяцев или на кэшбэк до 5000 рублей.
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

const textBlockTitle = (
  <>
    Есть Халва – активируйте акцию,{" "}
    <span>совершайте покупки и&nbsp;получайте до 36 месяцев рассрочки</span>
  </>
)

const secondTextBlockTitle = (
  <>
    Нет Халвы – оформляйте карту{" "}
    <span>и после получения не забудьте зарегистрироваться в акции</span>
  </>
)

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader
        showHint
        countDownTime={countDownTime}
        hint="Активируйте акцию «Кэшбэк до&nbsp;100% на подарки любимым» с&nbsp;17.02 по 09.03, совершайте покупки и участвуйте 
        в&nbsp;розыгрыше призов"
        hintTitle={
          <>
            Участвуй в<br /> розыгрыше
          </>
        }
      />
      <StaticBanner description={bannerDescription} title={bannerTitle} orderNum="1" />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYearMedia"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как принять участие в&nbsp;розыгрыше"
        variant="genderAction"
        orderNum="3"
      />
      <FormPKW
        formBtnText="Оформить карту"
        dataLayerName="shortPersonalForm"
        withTimer
        countDownTime={countDownTime}
        orderNum="4"
        title={formTitle}
        variant="btsAuto"
        isGreenApprove
        progressBar
      />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="5"
        variant="genderAction"
      />
      <TwoChoice
        orderNum="6"
        variant="genderAction"
        textBlockTitle={textBlockTitle}
        secondTextBlockTitle={secondTextBlockTitle}
      />
      <Questions questionList={QUESTIONS_FOR_HALVA_PKW} orderNum="7" />
      <NewFooter ligal={ligal} orderNum="8" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/gender-action/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
