import React, { ReactNode } from "react"

import Container from "@ecom/ui/components/Container"
import Grid from "@ecom/ui/components/Grid"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import clsx from "clsx"
import HalvaQuestion from "./img/halvaQuestion"
import Halva from "./img/halva"
import HalvaPurple from "./img/halvaShapka"

import * as styles from "./twoChoice.module.scss"
import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import HalvaCheckRed from "./img/halvaCheckRed"
import HalvaQuestionBlue from "./img/halvaQuestionBlue"

type AboutHalvaProps = {
  orderNum?: string
  newYear?: boolean
  textBlockTitle?: ReactNode
  title?: ReactNode
  secondTextBlockTitle?: ReactNode
  variant?:
    | "standart"
    | "shapka"
    | "kiberponedelnik"
    | "1500bonus"
    | "newYearMediaRuby"
    | "genderAction"
    | "nedeliUmnyhRassrochek"
  swapCard?: boolean
  swapCardText?: boolean
  swapBtnText?: boolean
}

const handleClick = () => {
  scrollToPersonalForm()

  pushToDataLayer({
    event: "buttonClick",
    name: "Оформить карту",
    placement: "third_screen_button_halva",
  })
}

const handleClickLink = () => {
  pushToDataLayer({
    event: "buttonClick",
    name: "Регистрация",
    placement: "third_screen_button_halva",
  })
}

const handleClickShapka = () => {
  const map = document.getElementById("map")
  if (map) {
    map.scrollIntoView({ behavior: "smooth", block: "center" })
  }
  pushToDataLayer({
    event: "buttonClick",
    name: "Ждем тебя в «Шапке»! ",
    placement: "map_halva",
  })
}

const handleClickKiberponedelnik = () => {
  pushToDataLayer({
    event: "buttonClick",
    name: "twist",
    placement: "wheel_of_fortune",
  })
}

export function TwoChoice({
  orderNum,
  newYear = false,
  textBlockTitle = <>Есть «Халва» - регистрируйся в&nbsp;акции прямо сейчас!</>,
  secondTextBlockTitle = (
    <>Нет «Халвы» - оформляй карту и после получения не забудь зарегистрироваться в акции</>
  ),
  title = false,
  variant = "standart",
  swapCard = false,
  swapCardText = false,
  swapBtnText = false,
}: AboutHalvaProps) {
  const cardText = swapCardText ? secondTextBlockTitle : textBlockTitle
  const secondCardText = swapCardText ? textBlockTitle : secondTextBlockTitle
  function CtaLinkFirstCard() {
    if (newYear) {
      return (
        <Link
          href="https://halvapoly.halva.games/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btnNY}
          underline="none"
          onClick={handleClickLink}
        >
          Играть
        </Link>
      )
    }
    if (variant === "shapka") {
      return (
        <Button className={styles.btnPurple} onClick={handleClickShapka}>
          Ждем тебя в «Шапке»!
        </Button>
      )
    }
    if (variant === "kiberponedelnik") {
      return (
        <Link
          href="https://halvacard.ru/shops/actions/kiberfortuna"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btnMain}
          underline="none"
          onClick={handleClickKiberponedelnik}
        >
          Активировать
        </Link>
      )
    }
    if (variant === "1500bonus") {
      return (
        <Button className={styles.btn} onClick={handleClick}>
          Оформить карту
        </Button>
      )
    }
    if (variant === "genderAction") {
      return (
        <Link
          href="https://halvacard.ru/shops/actions/keshbek-do-100-na-podarki-lyubimym"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btnMain}
          underline="none"
          onClick={handleClickKiberponedelnik}
        >
          Активировать
        </Link>
      )
    }

    if (variant === "nedeliUmnyhRassrochek") {
      return (
        <Link
          href=" https://hva.im/QltQUH"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btnMain}
          underline="none"
          onClick={handleClickKiberponedelnik}
        >
          Активировать
        </Link>
      )
    }

    return (
      <Link
        href="https://halvacard.ru/shops/actions/otkryvaem-shubohranilishe"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.btn}
        underline="none"
        onClick={handleClickLink}
      >
        Регистрация
      </Link>
    )
  }

  function CtaLinkSecondCard() {
    if (variant === "1500bonus") {
      return (
        <Link
          href="https://go.halvacard.ru/email/web_invite_friend_lim/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btn}
          underline="none"
          onClick={handleClickLink}
        >
          Привести друга
        </Link>
      )
    }

    return (
      <Button onClick={handleClick} className={styles.btn}>
        {swapBtnText ? "Привести друга" : "Оформить карту"}
      </Button>
    )
  }

  const setFirstCardImage = () => {
    switch (variant) {
      case "shapka":
        return <HalvaPurple alt="bg image" className={styles.img} />

      case "standart":
        return <Halva alt="bg image" className={styles.img} />

      case "1500bonus":
        return <Halva alt="bg image" className={styles.img} />

      case "kiberponedelnik":
        return <HalvaCheckRed alt="bg image" className={styles.img} />

      case "newYearMediaRuby":
        return <HalvaCheckRed alt="bg image" className={styles.img} />

      case "genderAction":
        return <HalvaCheckRed alt="bg image" className={styles.img} />
      case "nedeliUmnyhRassrochek":
        return <HalvaCheckRed alt="bg image" className={styles.img} />
      default:
        return null
    }
  }

  const setSecondCardImage = () => {
    switch (variant) {
      case "newYearMediaRuby":
        return <HalvaQuestionBlue alt="bg image" className={styles.img} />
      case "kiberponedelnik":
        return <HalvaQuestionBlue alt="bg image" className={styles.img} />
      case "genderAction":
        return <HalvaQuestionBlue alt="bg image" className={styles.img} />
      case "nedeliUmnyhRassrochek":
        return <HalvaQuestionBlue alt="bg image" className={styles.img} />
      default:
        return <HalvaQuestion alt="bg image" className={styles.img} />
    }
  }

  return (
    <section
      data-exclude={orderNum}
      className={clsx(styles.section, styles[variant])}
      id="TwoChoice"
    >
      <Container className={styles.container}>
        {title && (
          <h2 className={clsx({ [styles.kiberponedelnik]: variant === "kiberponedelnik" })}>
            {title}
          </h2>
        )}
        <Grid container className={clsx(styles.gridContainer, swapCard && styles.swapCard)}>
          <Grid sm={12} md={6} className={clsx(styles.firstCard, styles[variant])}>
            <div className={styles.content}>
              <div className={clsx(styles.textBlock, styles[variant])}>{cardText}</div>
              {CtaLinkFirstCard()}
            </div>
            {setFirstCardImage()}
          </Grid>
          <Grid sm={12} md={6} className={clsx(styles.secondCard, styles[variant])}>
            <div className={styles.content}>
              <div className={clsx(styles.textBlock, styles[variant])}>{secondCardText}</div>
              {CtaLinkSecondCard()}
            </div>
            {setSecondCardImage()}
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
