// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--zMA5t";
export var btnBlock = "banner-module--btnBlock--eJp0h";
export var container = "banner-module--container--HFkAn";
export var date = "banner-module--date--H1UbG";
export var genderMedia = "banner-module--genderMedia--yMQis";
export var hideMobBlock = "banner-module--hideMobBlock--dFkwe";
export var img = "banner-module--img--7UD2G";
export var mobBtn = "banner-module--mobBtn--KtDVJ";
export var pkwIndex = "banner-module--pkwIndex--4d+8Z";
export var secondContainer = "banner-module--secondContainer--XEj7x";
export var section = "banner-module--section--0ypxg";
export var text = "banner-module--text--1NRbF";